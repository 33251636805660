import { Box, BoxProps } from "@chakra-ui/react";
import {
  BaseSyntheticEvent,
  createContext,
  FormEvent,
  Ref,
  useCallback,
  useMemo,
} from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";
import { asyncVoid } from "utils/asyncVoidHandler";
import useLatestRef from "utils/useLatestRef";
import useToastInvalidSubmitErrors from "./useToastInvalidSubmitErrors";
import HandledSubmitError from "./HandledSubmitError";

export type TExtraFormContext = {
  formId?: string;
  isDisabled: boolean;
  isLoaded: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ExtraFormContext = createContext<TExtraFormContext>({
  isDisabled: false,
  isLoaded: true,
});

export type OnValidSubmitContext<
  TFieldValues extends FieldValues,
  TContext extends object,
> = {
  event: BaseSyntheticEvent | undefined;
  formContext: UseFormReturn<TFieldValues, TContext>;
};

export type OnValidSubmitHandler<
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext extends object = any,
> = (
  data: TFieldValues,
  context: OnValidSubmitContext<TFieldValues, TContext>,
) => unknown;

export type FormProps<
  TFieldValues extends FieldValues,
  TContext extends object,
> = BoxProps & {
  isDisabled?: boolean;
  isLoaded?: boolean;
  formContext: UseFormReturn<TFieldValues, TContext>;
  formRef?: Ref<HTMLFormElement>;
  onSubmit?: (evt: FormEvent<HTMLFormElement>) => boolean | void | undefined;
  onValidSubmit: OnValidSubmitHandler<TFieldValues, TContext>;
};

export default function Form<
  TFieldValues extends FieldValues,
  TContext extends object,
>({
  formContext,
  formRef,
  id: formId,
  isDisabled = false,
  isLoaded = true,
  onSubmit,
  onValidSubmit,
  ...rest
}: FormProps<TFieldValues, TContext>) {
  const extraFormContext = useMemo(
    () => ({ formId, isDisabled, isLoaded }),
    [formId, isDisabled, isLoaded],
  );

  const onSubmitRef = useLatestRef(onSubmit);
  const onValidSubmitRef = useLatestRef(onValidSubmit);

  const toastInvalidErrors = useToastInvalidSubmitErrors();

  const handleSubmit = useCallback(
    (evt: FormEvent<HTMLFormElement>) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const submitResult = onSubmitRef.current?.(evt);
      if (submitResult === false) return;
      asyncVoid(
        formContext.handleSubmit(
          (formData, event) => {
            Promise.resolve(
              onValidSubmitRef.current(formData, { event, formContext }),
            ).catch((reason) => {
              if (!(reason instanceof HandledSubmitError)) throw reason;
            });
          },
          (errors) => {
            toastInvalidErrors(errors);
          },
        )(evt),
      );
    },
    [formContext, onSubmitRef, onValidSubmitRef, toastInvalidErrors],
  );

  return (
    <FormProvider {...formContext}>
      <ExtraFormContext.Provider value={extraFormContext}>
        <Box
          as="form"
          onSubmit={handleSubmit}
          noValidate
          id={formId}
          ref={formRef as Ref<HTMLDivElement>}
          {...rest}
        />
      </ExtraFormContext.Provider>
    </FormProvider>
  );
}
