import {
  ComponentType,
  MutableRefObject,
  ReactElement,
  ReactNode,
  RefCallback,
  useContext,
} from "react";
import {
  Controller,
  ControllerProps,
  FieldError,
  FieldPath,
  FieldValues,
  get,
  RegisterOptions,
  UseControllerReturn,
  useFormContext,
  UseFormRegisterReturn,
  UseFormReturn,
} from "react-hook-form";
import { Input, InputProps } from "@chakra-ui/react";
import { reach, ObjectSchema } from "yup";
import { captureException } from "@sentry/nextjs";
import { combineRefs } from "utils/misc";
import {
  getIsNotRefOrLazyDescription,
  NotRefOrLazyDescription,
} from "utils/schemas/utils";
import getErrorMessages from "components/Shared/getErrorMessages";
import { ExtraFormContext } from "./Form";
import FormFieldWrapper, { FormFieldWrapperProps } from "./FormFieldWrapper";

function useEnsureFormContext<TFieldValues extends FieldValues>() {
  const formContext = useFormContext<TFieldValues>();
  if (!formContext) {
    throw new Error("FormField must be used inside FormProvider!");
  }
  return formContext;
}

export function getFieldError<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(formContext: UseFormReturn<TFieldValues>, name: TName) {
  return get(formContext.formState.errors, name) as
    | FieldError
    | FieldError[]
    | undefined;
}

export function getFieldErrorMessage(
  fieldError: FieldError | FieldError[] | undefined,
): ReactNode {
  return getErrorMessages(fieldError, {
    getMessage: (error) => {
      if (!error.message && error.types) {
        return Object.keys(error.types).reduce<string[]>((acc, type) => {
          const msg = error.types?.[type];
          if (typeof msg === "string") {
            acc.push(msg);
          }
          return acc;
        }, []);
      }
      return error.message;
    },
  });
}

export function getFieldSchemaSpec<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(schema: ObjectSchema<TFieldValues>, name: TName) {
  const field = reach(schema, name);
  const description = field.describe();
  if (!getIsNotRefOrLazyDescription(description)) return;
  return description;
}

export function getIsFieldRequired(field: NotRefOrLazyDescription | undefined) {
  if (!field) return;

  if (field.type === "string") {
    return field.tests.some((t) => t.name === "required");
  }

  return !field.optional && !field.nullable;
}

export function getNestedFieldNotRequired<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(schema: ObjectSchema<TFieldValues>, name: TName) {
  try {
    const nestedFieldSpecParts = name.split(".").slice(0, -1);

    if (!nestedFieldSpecParts.length) return;

    return nestedFieldSpecParts.some(
      (p) =>
        !getIsFieldRequired(
          getFieldSchemaSpec(schema, p as FieldPath<TFieldValues>),
        ),
    );
  } catch (e) {
    captureException(e);
  }
}

type CreateFormFieldOptions<TFieldValues extends FieldValues> = {
  schema?: ObjectSchema<TFieldValues>;
};

type ConvenienceInputProps = Pick<
  InputProps,
  "autoComplete" | "autoFocus" | "placeholder"
>;

export type RenderInputProps = UseFormRegisterReturn &
  ConvenienceInputProps & {
    ["data-1p-ignore"]?: true;
    ["data-lpignore"]?: true;
  };

type UnconnectedFormFieldProps = FormFieldWrapperProps & {
  idPrefix?: string;
};

type BaseFormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UnconnectedFormFieldProps & {
  name: TName;
};

export type FormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = BaseFormFieldProps<TFieldValues, TName> &
  ConvenienceInputProps & {
    InputComponent?: ComponentType<RenderInputProps>;
    registerOptions?: RegisterOptions<TFieldValues, TName>;
    renderInput?: (props: RenderInputProps) => ReactElement;
    inputRef?: RefCallback<unknown> | MutableRefObject<unknown>;
  };

export type ControlledFormFieldWrapperProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UnconnectedFormFieldProps & {
  controller: UseControllerReturn<TFieldValues, TName>;
};

export type ControlledFormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = BaseFormFieldProps<TFieldValues, TName> & {
  renderInput: ControllerProps<TFieldValues, TName>["render"];
};

export default function createFormFieldComponents<
  TFieldValues extends FieldValues,
>({ schema }: CreateFormFieldOptions<TFieldValues> = {}) {
  function BaseFormField<
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >({
    children,
    idPrefix,
    isDisabled: isDisabledProp = false,
    isLoaded: isLoadedProp = true,
    isRequired: isRequiredProp,
    name,
    ...rest
  }: BaseFormFieldProps<TFieldValues, TName>) {
    const extraFormContext = useContext(ExtraFormContext);

    if (!extraFormContext) {
      throw new Error(
        "FormField must be used inside Form, which provides its context.",
      );
    }

    const {
      formId,
      isDisabled: isDisabledContext,
      isLoaded: isLoadedContext,
    } = extraFormContext;

    const fieldSpec = schema && getFieldSchemaSpec(schema, name);
    const isNestedFieldNotRequired =
      schema && getNestedFieldNotRequired(schema, name);

    return (
      <FormFieldWrapper
        id={`${idPrefix || formId || "form-field"}--${name.replace(".", "-")}`}
        data-form-field={name}
        isDisabled={isDisabledProp || isDisabledContext}
        isLoaded={isLoadedProp && isLoadedContext}
        isRequired={
          typeof isRequiredProp === "undefined"
            ? getIsFieldRequired(fieldSpec) && !isNestedFieldNotRequired
            : isRequiredProp
        }
        label={fieldSpec?.label}
        my={3}
        {...rest}
      >
        {children}
      </FormFieldWrapper>
    );
  }

  function FormField<
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >({
    inputRef,
    InputComponent = Input,
    autoComplete = "off",
    autoFocus,
    registerOptions,
    renderInput = (props) => <InputComponent {...props} />,
    placeholder,
    ...rest
  }: FormFieldProps<TFieldValues, TName>) {
    const { name } = rest;

    const formContext = useEnsureFormContext<TFieldValues>();

    const fieldError = getFieldError(formContext, name);

    const { ref: registerRef, ...inputProps } = formContext.register(
      name,
      registerOptions,
    );

    return (
      <BaseFormField
        errorMessage={getFieldErrorMessage(fieldError)}
        isInvalid={!!fieldError}
        {...rest}
      >
        {renderInput({
          ...inputProps,
          autoFocus,
          autoComplete,
          "data-1p-ignore": autoComplete === "off" || undefined,
          "data-lpignore": autoComplete === "off" || undefined,
          placeholder,
          ref: combineRefs(registerRef, inputRef),
        })}
      </BaseFormField>
    );
  }

  function ControlledFormFieldWrapper<
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >({
    controller,
    ...rest
  }: ControlledFormFieldWrapperProps<TFieldValues, TName>) {
    const { error } = controller.fieldState;
    return (
      <BaseFormField
        name={controller.field.name}
        errorMessage={getFieldErrorMessage(error)}
        isInvalid={!!error}
        {...rest}
      />
    );
  }

  function ControlledFormField<
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >({ renderInput, ...rest }: ControlledFormFieldProps<TFieldValues, TName>) {
    const { name } = rest;

    const formContext = useEnsureFormContext<TFieldValues>();

    const { control } = formContext;

    const fieldError = getFieldError(formContext, name);

    return (
      <BaseFormField
        errorMessage={getFieldErrorMessage(fieldError)}
        isInvalid={!!fieldError}
        {...rest}
      >
        <Controller<TFieldValues, TName>
          name={name}
          control={control}
          render={renderInput}
        />
      </BaseFormField>
    );
  }

  return { FormField, ControlledFormFieldWrapper, ControlledFormField };
}
