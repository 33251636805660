export default class HandledSubmitError extends Error {
  public originalError: Error;

  constructor(originalError: Error) {
    super();
    this.originalError = originalError;
    Object.setPrototypeOf(this, HandledSubmitError.prototype);
  }

  get message() {
    return this.originalError.message;
  }
}
