import { ReactNode } from "react";
import getMaybeList from "components/Shared/getMaybeList";

type MessageObj = { message?: string | null };

function defaultGetMessage<E extends MessageObj>(error: E) {
  return error.message;
}

type Options<E extends MessageObj> = {
  getMessage?: (error: E) => ReactNode | ReactNode[];
};

function isReadonlyArray<T>(
  arg: T | T[] | ReadonlyArray<T> | null | undefined,
): arg is ReadonlyArray<T> {
  return Array.isArray(arg);
}

export default function getErrorMessages<E extends MessageObj>(
  error: E | E[] | ReadonlyArray<E> | null | undefined,
  { getMessage = defaultGetMessage }: Options<E> = {},
) {
  if (!error) return null;

  const errorsArr =
    Array.isArray(error) || isReadonlyArray(error) ? error : [error];

  const messages = errorsArr.reduce<ReactNode[]>((acc, err) => {
    const msg = getMessage(err);
    const msgArr = isReadonlyArray(msg) ? msg : [msg];
    msgArr.forEach((m) => {
      if (m) acc.push(m);
    });
    return acc;
  }, []);

  return getMaybeList(messages);
}
