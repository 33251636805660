import {
  ISchema,
  mixed,
  MixedSchema,
  number,
  reach,
  Reference,
  SchemaDescription,
  SchemaFieldDescription,
  SchemaInnerTypeDescription,
  SchemaObjectDescription,
  string,
} from "yup";
import { stateAbbreviations } from "utils/usStatesData";

// eslint-disable-next-line @typescript-eslint/ban-types
export type AnyPresentValue = {};

export const nullableNumber = number()
  .nullable()
  .transform((_, v: unknown) => (v === "" ? null : v));

export type EnumObj<E> = Record<E extends string ? `${E}` : never, string>;

function getEnumValues<E>(enumObj: EnumObj<E>) {
  return Object.values(enumObj as { [id: string]: string }) as E[];
}

export function getEnumSchema<E extends AnyPresentValue>(enumObj: EnumObj<E>) {
  return mixed<E>().oneOf(
    getEnumValues(enumObj),
    // eslint-disable-next-line no-template-curly-in-string
    "“${value}” is not one of the available options.",
  ) as MixedSchema<E>;
}

export function getNullableEnumSchema<E extends AnyPresentValue>(
  enumObj: EnumObj<E>,
) {
  return getEnumSchema<E>(enumObj).nullable();
}

export function getStringUnionSchema<U extends AnyPresentValue>(
  stringUnion: readonly U[],
) {
  return mixed<U>().oneOf(
    stringUnion.slice(0),
    // eslint-disable-next-line no-template-curly-in-string
    "“${value}” is not one of the available options.",
  ) as MixedSchema<U>;
}

export const usState = string().oneOf(stateAbbreviations);

export function isRef(ref: unknown): ref is Reference {
  if (!ref || typeof ref !== "object") return false;
  // eslint-disable-next-line no-underscore-dangle
  return "__isYupRef" in ref && (ref.__isYupRef as boolean);
}

export type ComparisonRef = {
  ref: Reference;
  getFromSchema: () => ISchema<unknown> | undefined;
};

export function getRefLabel(ref: ComparisonRef) {
  const fromSchema = ref.getFromSchema();
  if (!fromSchema) return;
  const field = reach(fromSchema, ref.ref.path);
  if (!field) return;
  const description = field.describe();
  if (!description || !("label" in description)) return;
  return description.label;
}

export type NotRefOrLazyDescription =
  | SchemaDescription
  | SchemaObjectDescription
  | SchemaInnerTypeDescription;

export function getIsNotRefOrLazyDescription(
  fieldDescription: SchemaFieldDescription,
): fieldDescription is NotRefOrLazyDescription {
  return "optional" in fieldDescription;
}
