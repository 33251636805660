import React, { ReactNode } from "react";
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  Skeleton,
  SkeletonProps,
} from "@chakra-ui/react";

export const MARGIN_Y = 3;

export type ContentComponents = Record<
  "label" | "input" | "error" | "helpText",
  React.ReactNode
>;

function defaultRenderContent({
  label,
  helpText,
  input,
  error,
}: ContentComponents) {
  return (
    <>
      {label}
      {helpText}
      {input}
      {error}
    </>
  );
}

export type FormFieldWrapperProps = Omit<FormControlProps, "label"> & {
  errorMessage?: ReactNode;
  errorProps?: FormErrorMessageProps;
  helpText?: ReactNode;
  helpTextProps?: FormHelperTextProps;
  isLoaded?: boolean;
  label?: ReactNode;
  labelProps?: FormLabelProps;
  renderContent?: (components: ContentComponents) => ReactNode;
  skeletonProps?: Omit<SkeletonProps, "isLoaded">;
};

export default function FormFieldWrapper({
  children,
  errorMessage,
  errorProps,
  helpText,
  helpTextProps,
  isLoaded = true,
  label,
  labelProps,
  renderContent = defaultRenderContent,
  skeletonProps,
  ...rest
}: FormFieldWrapperProps) {
  return (
    <FormControl my={MARGIN_Y} {...rest}>
      {renderContent({
        label: label && <FormLabel {...labelProps}>{label}</FormLabel>,
        input: (
          <Skeleton isLoaded={isLoaded} borderRadius={10} {...skeletonProps}>
            {children}
          </Skeleton>
        ),
        error: (
          <FormErrorMessage {...errorProps}>{errorMessage}</FormErrorMessage>
        ),
        helpText: helpText && (
          <FormHelperText {...helpTextProps}>{helpText}</FormHelperText>
        ),
      })}
    </FormControl>
  );
}
