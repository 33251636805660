import { useToast } from "@chakra-ui/react";
import { captureException } from "@sentry/nextjs";
import { compact, uniq } from "lodash";
import { useCallback } from "react";
import { FieldError, FieldErrors, FieldValues } from "react-hook-form";
import { formatInteger } from "utils/formatting";
import getMaybeList from "components/Shared/getMaybeList";

function isFieldError(value: unknown): value is FieldError {
  return (
    !!value && typeof value === "object" && "ref" in value && "type" in value
  );
}

function flattenErrors<TFieldValues extends FieldValues>(
  errors: FieldErrors<TFieldValues>,
  depth = 0,
) {
  if (depth > 10) throw new Error("Reached maximum recursion depth!");

  if (typeof errors !== "object")
    throw new TypeError("Expected errors to be an object");

  if (isFieldError(errors)) {
    return [errors];
  }

  return Object.keys(errors).reduce<FieldError[]>((acc, errorKey) => {
    const fieldError = errors[errorKey];

    if (!fieldError) return acc;

    if (isFieldError(fieldError)) {
      acc.push(fieldError);
      return acc;
    }

    if (Array.isArray(fieldError)) {
      (fieldError as FieldErrors<TFieldValues>[]).forEach((arrFieldError) => {
        const flattenedArrErrors = flattenErrors(arrFieldError, depth + 1);
        acc.push(...flattenedArrErrors);
      });
      return acc;
    }

    const subObjErrors = flattenErrors(
      fieldError as FieldErrors<TFieldValues>,
      depth + 1,
    );
    acc.push(...subObjErrors);

    return acc;
  }, []);
}

export default function useToastInvalidSubmitErrors<
  TFieldValues extends FieldValues,
>() {
  const showToast = useToast({
    status: "error",
    title: "An error occurred when trying to submit the form",
    duration: 2500,
    isClosable: true,
  });

  const handleInvalidSubmit = useCallback(
    (errors: FieldErrors<TFieldValues>) => {
      let flattenedErrors: FieldError[] | undefined;
      try {
        flattenedErrors = flattenErrors(errors);
      } catch (e) {
        captureException(e);
      }

      if (!flattenedErrors?.length) {
        showToast();
        return;
      }

      const numErrors = flattenedErrors.length;

      const errorMessages = flattenedErrors.map((err) => err.message);

      const uniqMessages = uniq(compact(errorMessages));

      const limitedMessages = uniqMessages.slice(0, 10);

      const numExtraMessages = uniqMessages.length - limitedMessages.length;

      showToast({
        title: `${formatInteger(numErrors)} error${
          numErrors === 1 ? "" : "s"
        } need${numErrors === 1 ? "s" : ""} to be fixed before submission${
          uniqMessages.length ? ":" : ""
        }`,
        description: (
          <>
            {getMaybeList(limitedMessages)}
            {!!numExtraMessages && (
              <div>
                <em>…and {formatInteger(numExtraMessages)} more…</em>
              </div>
            )}
          </>
        ),
        duration: 3000 + limitedMessages.length * 1500,
      });
    },
    [showToast],
  );

  return handleInvalidSubmit;
}
