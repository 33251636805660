import { ComponentType, ReactNode } from "react";
import { UnorderedList, ListItem } from "@chakra-ui/react";

type GetMaybeListOptions = {
  ListComponent?: ComponentType<{ children: ReactNode }>;
};

export default function getMaybeList(
  items: ReactNode | ReactNode[] | null | undefined,
  { ListComponent = UnorderedList }: GetMaybeListOptions = {},
) {
  if (!items) return null;

  const itemsArr = Array.isArray(items) ? items : [items];

  if (!itemsArr?.length) return null;

  if (itemsArr.length > 1) {
    return (
      <ListComponent>
        {itemsArr.map((msg, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={idx}>{msg}</ListItem>
        ))}
      </ListComponent>
    );
  }

  return itemsArr[0];
}
